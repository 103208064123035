<template>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
            btn-background="bg-gradient-success"
            :dark-mode="true"
          />
        </div>
      </div>
    </div>
    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder text-success text-gradient">
                      Password Reset
                    </h3>
                    <p class="mb-0">Enter your email to get your password reset</p>
                  </div>
                  <div class="card-body">
                    <form id="login_form" role="form" class="text-start" @submit.prevent="login">
                      <label>Email</label>
                      <soft-input
                        id="email"
                        type="text"
                        placeholder="Email"
                        name="email"
                      
                      />
                     
  
                      <div class="text-center">
                        <soft-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="info"
                          full-width
                          >Submit
                        </soft-button>
                      </div>
                    </form>
                  </div>
           
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
                >
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved9.jpg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <otp-modal :token="token" :is-open="isModalOpen" @close="closeModal"  />
    </main>
  </template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  import axios from "axios";
  import SoftInput from "@/components/SoftInput.vue";
  import OtpModal from "@/views/components/OTPmodal.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import { mapMutations } from "vuex";
  import Swal from 'sweetalert2';
  import $ from 'jquery';
  export default {
    name: "SignIn",
    data() {
      return {
        formData: {
          email: "",
        },
        isModalOpen:false,
        validation_fail: false,
        statusfail: false,
        token:""
      };
    },
    components: {
      Navbar,
      SoftInput,
      SoftButton,
      OtpModal
    },
    created() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    beforeUnmount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
      async login() {
        const formData = {
          email: $('#email').val(),
          password: $('#password').val(),
        };
        const loadingSpinner = Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
        try
        {
          await axios
          .post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/reset_password_coordinator`, formData)
          .then(response => {
            if (response.data.status == 200) {
                Swal.fire({
              title: "Success!",
              text: "We send you a reset password in your email account.",
              icon: "success"
            }).then(() => {
                this.$router.push({ name: 'Sign In' });
            });
              
            }
            else {
              Swal.fire({
              title: "Error!",
              text: "no record with that email in our system.",
              icon: "error"
            });
            }
          })
          .catch(error => {
            console.error("Backend error:", error);
          });
        }catch (error) {
            console.error('Backend error:', error);
            this.validation_fail = true;
          } finally {
            loadingSpinner.close();
          }
      },
      closeModal(){
        this.isModalOpen=false;
      }
    },
  };
  </script>
  