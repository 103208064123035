<template>
  
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1 text-uppercase"> {{ firstName }} {{ lastName }}  </h5>
              <p class="mb-0 text-sm font-weight-bold text-uppercase">{{ companyName }}</p>
            </div>
          </div>
          
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
          </div>
          <div class="mb-4  mt-4 row">
          <div class="col-md-3">
            <input v-model="filters.reportType" class="form-control" placeholder="Filter by Report Type">
          </div>
          <!-- <div class="col-md-3">
            <input v-model="filters.clientName" class="form-control" placeholder="Filter by Client Name">
          </div> -->
          <!-- <div class="col-md-3">
            <input v-model="filters.uploadDate" type="date" class="form-control" placeholder="Filter by Upload Date">
          </div> -->
          <div class="col-md-3">
            <button @click="resetFilters" class="btn btn-info">Reset Filters</button>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
      <div class="col-12">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7"
              >
                Client Name
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7 ps-2"
              >
                Report Type
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7 ps-2"
              >
                Report Description
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7 ps-2"
              >
               Date Uploaded
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="report in filteredReports" :key="report.id">
              <td>
                <div class="d-flex px-2">
                  <div>
                  
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm" v-if="(report.client)">{{ report.client.first_name  }} {{ report.client.last_name
  }}</h6>
                    <h6 class="mb-0 text-sm" v-else>{{ report.report_type_data  }} </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-lg font-weight-bold mb-0">{{ report.report_type_data  }} </p>
              </td>
              <td>
                <p class="text-lg font-weight-bold mb-0">{{ report.description  }} </p>
              </td>
              <td>
                <p class="text-lg font-weight-bold mb-0">{{  formatDate(report.created_at) }} </p>
              </td>
              <td>
                <!-- <button @click="openFileSwal(report.report_file, getFileNameFromUrl(report.report_file))" 
                class="btn btn-primary m-1"
                >
                  View File
                  
                </button> -->
                <button @click="download_invoice(report.report_file, getFileNameFromUrl(report.report_file))" 
                class="btn btn-success m-1"
                >
                  Download
                  
                </button>
              </td>
             
           
            </tr>
          </tbody>
        </table>
    
    </div>
    
  </div>
   
    </div>
    <modal :is-open="isModalOpen" @close="closeModal"/>
  </template>
  
  <script>
  import Modal from "@/views/components/UploadInvoiceModal.vue";
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import $ from 'jquery';
  
  export default {
    name: "ReportsPage",
    components: {
      Modal,
    },
    data() {
      return {
        isModalOpen: false,
        reports: [],
        filters: {
          reportType: '',
          clientName: '',
          uploadDate: ''
        }
      };
    },
    computed: {
      firstName() {
        return localStorage.getItem('first_name') || 'Welcome';
      },
      lastName() {
        return localStorage.getItem('last_name') || 'Here';
      },
      companyName() {
        return localStorage.getItem('company_name') || 'Blessed FMS';
      },
      filteredReports() {
        return this.reports.filter(report => {
            // Check for report type match (you may adjust this based on your actual filtering logic)
            const reportTypeMatch = report.description?.toLowerCase().includes(this.filters.reportType.toLowerCase()) ||
                                    report.report_type_data?.toLowerCase().includes(this.filters.reportType.toLowerCase());

            // Check for client name match if client exists
            const clientNameMatch = report.client
                ? `${report.client.first_name || ''} ${report.client.last_name || ''}`.toLowerCase().includes(this.filters.clientName.toLowerCase())
                : false;

            // Check for upload date match if upload date filter is set
            const uploadDateMatch = this.filters.uploadDate
                ? this.formatDate(report.created_at).includes(this.filters.uploadDate)
                : true;

            // Combine all conditions to filter the reports
            return reportTypeMatch && (clientNameMatch || uploadDateMatch);
        });
      }
    },
    methods: {
      btn_time_entry() {
        this.isModalOpen = true;
      },
      resetFilters() {
        this.filters = {
          reportType: '',
          clientName: '',
          uploadDate: ''
        };
      },
      formatDate(created_at) {
        const date = new Date(created_at);
        const month = date.toLocaleString('en-US', { month: 'long' });
        const day = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
        return formattedDate;
      },
      download_invoice(url, fileName) {
        const component = this;
        $.ajax({
          url: url,
          type: 'GET',
          xhrFields: {
            responseType: 'blob'
          },
          success: function(data) {
            component.downloadBlob(data, fileName);
          },
          error: function(jqXHR, textStatus, errorThrown) {
            console.error('Error fetching image:', errorThrown);
          }
        });
      },
      downloadBlob(blob, fileName) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      closeModal() {
        this.isModalOpen = false;
      },
      getFileNameFromUrl(url) {
        const parts = url.split('/');
        return parts[parts.length - 1];
      },
      openFileSwal(file_data, filename) {
        Swal.fire({
          title: filename,
          html: this.getHtmlContent(file_data, filename),
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
        });
      },
      getHtmlContent(file_data, file_name) {
        let content = '';
  
        if (this.isImage(file_name)) {
          content += `<img src="${file_data}" style="max-width: 100%; max-height: 100%;">`;
        } else if (this.isExcel(file_name)) {
          content += `Please Download The file to view: <a class="bg-teal-500" href="${file_data}" download="${file_name}">Click here to download the file</a>`;
        } else {
          content += "Unsupported file type";
        }
  
        return content;
      },
      isImage(fileName) {
        const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".pdf"];
        return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
      },
      isExcel(fileName) {
        return fileName.toLowerCase().endsWith(".xlsx");
      },
      async fetch_reports() {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_reports`, { token: token }, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          this.reports = response.data.data || []; // Ensure reports is initialized as an empty array if no data returned
          console.log(response.data.data);
        } catch (error) {
          console.error('Error fetching reports:', error);
          // Handle error (show message, retry, etc.)
        }
      }
    },
    mounted() {
      this.fetch_reports();
    }
  };
  </script>
  
  