<template>
    <div v-if="isOpen" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ title }}</h3>
          <button @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
          <form>
            <div class="relative w-full mb-3">
              <center><h3 class="font-bold">OTP CODE</h3></center>
              <label
                class="block text-blueGray-600 text-xs mb-4 mt-4"
              >
                We have sent to your email the Authentication Code.<br> Please check your email for your OTP code.
              </label>
              <br>
              <span v-if="validation_fail" class="text-red-500 text-sm">PLease enter a valid 6 digit OTP code</span>
              <span v-if="invalid_code" class="text-red-500 text-sm">Invalid Code</span>
              <!-- Display OTP input fields with spaces -->
              <div class="otp-input-container">
               
              <input
                v-for="(digit, index) in 6"
                :key="index"
                v-model="otpDigits[index]"
                @input="handleInput(index)"
                class="otp-input-digit"
                maxlength="1"
                :ref="`otpInput-${index}`"
              />
            </div>
            
  
            </div>
  
            <div class="text-center mt-6">
              <soft-button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button" @click="submit_otp"
                color="info"
              >
                Submit
              </soft-button>
            </div>
          </form>
          
        </div>
      </div>
    </div>
  </template>
  
    
    <script>
  import axios from "axios";
  import SoftButton from "@/components/SoftButton.vue";
  import Swal from 'sweetalert2/dist/sweetalert2.js';
    export default {
      data() {
            return {
              otpDigits: ["", "", "", "", "", ""],
              otpValue: "", 
              validation_fail: false,
              invalid_code:false
            };
          },
          components:
          {
            SoftButton,
          },
     
      props: {
              isOpen: Boolean,
              title: String,
              token:String
              },
      
      methods: {
        closeModal() {
          this.resetInputs();
          this.$emit('close');
        },
        handleInput(index) {
          if (index < this.otpDigits.length - 1 && this.otpDigits[index] !== "") {
            this.$refs[`otpInput-${index + 1}`].focus();
          }
        },
        async submit_otp() {
          if (this.otpDigits && this.otpDigits.length === 6 && this.otpDigits.every(digit => /\d/.test(digit)))
        {
          this.validation_fail = false;
          // Show loading spinner
          const loadingSpinner = Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
  
          try {
            const formData ={
              token: this.token,
              otp: this.otpDigits.join('')
            }
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/coordinator_verify_otp`, formData);
          
            if (response.data.status === 200) {
             const expirationTime = new Date().getTime() + 10 * 60 * 1000;
            localStorage.setItem('token', this.token);
            localStorage.setItem('first_name',  response.data.data.first_name);
            localStorage.setItem('last_name', response.data.data.last_name);
            localStorage.setItem('company_name', response.data.data.region_center);
            localStorage.setItem('tokenExpiration', expirationTime);
            this.$store.dispatch('updateFirstName', response.data.data.first_name);
            this.$store.dispatch('updateLastName', response.data.data.last_name);
            this.$store.dispatch('updateCompanyName', response.data.data.company_name);
            const targetRoute = '/dashboard';
            this.$router.push({ path: targetRoute });
             
            }  else {
              this.invalid_code = true;
            }
          } catch (error) {
            console.error('Backend error:', error);
            this.validation_fail = true;
          } finally {
            // Close loading spinner
            loadingSpinner.close();
          }
         
        } else {
          this.validation_fail = true;
          this.invalid_code = false;
        }
      },
        resetInputs() {
        this.otpDigits = ["", "", "", "", "", ""];
      },
       
  
      },
      mounted()
      {
          this.resetInputs();
          console.log('Token in OTPModal:', this.token);
      }
   
    };
    </script>
    
    <style scoped>
    /* Add your styling for the modal */
    .otp-input-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .otp-input-digit {
      display: inline-block;
      width: 2rem; /* Adjust the width as needed */
      height: 2rem; /* Adjust the height as needed */
      font-size: 1.5rem; /* Adjust the font size as needed */
      text-align: center;
      margin: 0 0.5rem; /* Adjust the margin as needed */
      border: 1px solid #ccc; /* Add a border for separation */
      border-radius: 4px; /* Add border-radius for rounded corners */
      padding: 0.5rem; /* Add padding for better appearance */
    }
    .modal-overlay {
      /* Overlay styles */
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1040;
    }
  
  
    .modal-container {
      /* Modal styles */
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 1050;
    }
    
    .modal-header {
      /* Header styles */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .modal-header h3 {
      margin: 0;
    }
    
    .modal-header button {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
    </style>
    